.nav {
	list-style: none;
	margin-left: -1.5em;

	li {
		display: inline-block;
		margin-right:-0.25em;
	}

	a {
		display: inline-block;
		padding: 1em 1.5em;
	}
}
