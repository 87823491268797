html {
    font:#{($base-font-size/16px)*1em}/#{$line-height-ratio} $base-font-family;
    overflow-y:scroll;
    min-height:100%;
    color: $base-font-color;
}

.centered {
	max-width: $base-page-width;
	padding: 0 $base-spacing-unit;
	margin-left: auto;
	margin-right: auto;
	position: relative;
}

a {
	color: $base-brand-color;
	text-decoration: none;
	transition: all 200ms ease;
	
	&:hover {
		color: lighten($base-brand-color, 20%);
	}
}

.muted-link {
	color: $base-font-color;
}

.cf:before,
.cf:after {
	content: " ";
	display: table; 
}

.cf:after {
	clear: both;
}

.muted {
	color: lighten($base-font-color, 10%);
}

.smaller {
	font-size: 0.75*$base-font-size;
}

.align--center {
	text-align: center;
}
