/* Set Custom Variables here
 * ====================================================
*/
$base-font-size:    16px!default;
$base-line-height:  24px!default;
$base-spacing-unit: $base-line-height!default;
$line-height-ratio: $base-line-height / $base-font-size;
$base-page-width: 960px!default;

$breakpoints: (
	'palm' '(max-width: 480px)',
	'lap' '(min-width: 481px) and (max-width: 825px)',
	'portable' '(max-width: 825px)',
	'desk' '(min-width: 826px)'
);

$base-font-family: 'Raleway', sans-serif;

$base-brand-color: #DEC58A;
$secondary-brand-color: #083549;
$dark-brand-color: darken($secondary-brand-color, 5%);
$base-font-color: #fff;
$base-ui-color: #124053;
$base-ui-radius: 4px;
$push: true;
$use-markup-fix: false;


$base-font-size:    18px;
$base-line-height:  27px;


/* Load Frameworks
 * ====================================================
*/
@import "vendor/csswizardry-grids";
@import "vendor/baguetteBox";
@import "generic/responsive-helpers";

/* Load partials
 * ====================================================
*/
@import "generic/normalize";
@import "generic/reset";
@import "generic/shared";
@import "generic/global";
@import "generic/buttons";
@import "generic/nav";

@import "theme/typography";
@import "theme/layout";
@import "theme/landing";
@import "theme/about";
@import "theme/termine";
@import "theme/gallery";

