.header {
	box-sizing: border-box;
	position: relative;
	padding: 8*$base-spacing-unit 0 10*$base-spacing-unit;
}

.header__title {
	text-transform: uppercase;
	color: $base-brand-color;
}

.header__video {
    position: fixed;
    top: 50%;
    left: 50%;
    min-width: 100%;
    min-height: 100%;
    width: auto;
    height: auto;
    z-index: -100;
    transform: translateX(-50%) translateY(-50%);
}

.header__img {
	padding-left: 3*$base-spacing-unit;
	padding-top: $base-spacing-unit;

	img {
		max-width: 100%;
		box-shadow: 0 5px 50px transparentize(#000, .65);
	}
}


.social-media-links {
	list-style: none;
	text-align: center; 
	font-size: 1.5*$base-font-size;
	margin: 0;
	margin-top: -6*$base-spacing-unit;
	margin-bottom: 3*$base-spacing-unit;

	li {
		display: inline-block;

		a {
			padding: 10px;
			color: #fff;
		}
	}
}


@include grid-media-query("portable") {
	.header {
		text-align: center;
		padding: 8*$base-spacing-unit 0 6*$base-spacing-unit;

		.lead, p {
			text-align: center !important;
		}
	}

	.header__title { 
		font-size: 2.5*$base-font-size;
		text-align: center;
	}
	
	.header__img {
		padding-left:0;
	}
}
