html {
  height: 100%;
  background: $dark-brand-color url(/assets/img/bg.jpg) no-repeat center center fixed;
  background-size: cover;
}

body {
    padding-top: 3*$base-spacing-unit;
    background-color: transparentize($secondary-brand-color, 0.5);
}


hr {
    width: 120px;
    height: 0;
    border-top: 4px solid $base-brand-color;
    margin: 0 auto 2*$base-spacing-unit auto;
}


img {
    max-width: 100%;
}

.main-nav {
    position: fixed;
    width: 100%;
    z-index: 100;
    font-family: 'Poiret One', cursive;
    line-height: 1;
    top: 0;
    padding: $base-spacing-unit 0;
    background-color: $secondary-brand-color;
    transition: all ease 300ms;

    &.transparent {
        background-color: transparent;
    }

    a {
        color: $base-brand-color;
        border-bottom: 2px solid transparent;
        line-height: 1;

        &:hover,
        &.active {
            border-bottom: 2px solid $base-brand-color;
        }
    }
}

.main-nav__title {
    margin-bottom: 0.5*$base-spacing-unit;
    width: 40%;

    a, a:hover {
        border-bottom: 0;
        line-height: 1;
    }
}

.main-nav__links {
    font-size: 1.5*$base-font-size;
    margin: 0;
    width: 30%;

    a {
        padding: 0.6 * $base-spacing-unit $base-spacing-unit;
    }
}

.portable-nav {
    text-align: center;
    background-color: $secondary-brand-color;
    transition: all ease 300ms;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 100;
    transform: translateY(-2.5*$base-spacing-unit);
    box-shadow: 0 5px 20px rgba(0,0,0,.3);

    &.transparent {
        background-color: transparent;
        transform: translateY(0);
        box-shadow: none;
    }

    a {
        color: $base-brand-color;
        border-bottom: 2px solid transparent;
        line-height: 1;

        &.active {
            border-bottom: 2px solid $base-brand-color;
        }
    }
}

.portable-nav__title {
    padding: 0.5*$base-spacing-unit 0 0;
    margin: 0;
    font-size: 2*$base-font-size;

    a, a:hover {
        border-bottom: 0;
        line-height: 1;
    }
}

.portable-nav__links {
    margin: 0;

    a {
        padding: 0.75 * $base-spacing-unit 0.5*$base-spacing-unit;
    }


}

.portable-nav {
    display: none;
}

@include grid-media-query("portable") {
    .indent {
        padding: 0;
    }

    .main-nav {
        display: none;
    }

    .portable-nav {
        display: block;
    }

    .h2--lead {
        font-size: 2*$base-font-size;
    }
}





.section {
	padding: 3*$base-spacing-unit 0;
}

.section__heading {
    @extend .cursive;
    font-size: 2*$base-font-size;
    text-align: left;
    color: $base-brand-color;
    font-style: normal;
    display: inline-block;
}

.dark-bg {
    background-color: rgba(0,0,0,.3);
}

.darker-bg {
    background-color:  rgba(0,0,0,.4);
}

.section--about {
    @extend .darker-bg;
}

.section--media {
    @extend .darker-bg;

    iframe {
        box-shadow: 0 5px 50px transparentize(#000, .65);
    }
}





.footer {
	padding: $base-spacing-unit 0;
	font-size: 0.75*$base-font-size;
	text-align: center;

    a {
        color: lighten($secondary-brand-color, 30%);
    }
}

.release-content {
    em, p {
        @extend .muted;
    }

    li > em {
        padding: 0 0.25*$base-spacing-unit;
    }

    p {
        @extend .indent;

        em {
            color: white;
        }
    }

    ol {
        @extend .landmark
    }
}
