.button {
	padding: 1em 1.5em;
	border-radius: $base-ui-radius;
	background-color: $base-ui-color;
	text-decoration: none;
	cursor: pointer;
	color: $base-font-color;
	font-weight: bold;
	white-space: nowrap;
	display: inline-block;
	text-align: center;
	box-sizing: border-box;

	&:hover {
		background-color: darken($base-ui-color, 5%);
		text-decoration: none;
        color: $dark-brand-color;
	}
}

.button--primary {
	background-color: $base-brand-color;
	color: $dark-brand-color;

	&:hover {
		background-color: lighten($base-brand-color, 7%);
	}
}

.button--stripped {
	border: none;
	background: transparent;
	font-weight: normal;

	&:hover {
		background: transparent;
	}
}

.button--hollow {
	background: transparent;
	border: 2px solid $base-ui-color;

	&:hover {
		background: transparent;
		border-color: darken($base-ui-color, 5%);
	}
}

@media only screen and #{nth(nth($breakpoints, 3), 2)} { // lap breakpoint
	.button {
		display: block;
		width: 100%;
		margin-bottom: $base-spacing-unit;
	}
}
