.termine__item {
    @extend .islet;
}

.termine__date {
	font-weight: bold;
	font-family: $base-font-family;
	font-size: $base-font-size;
}

.termine__location {
	@extend .islet;
    @extend .cursive;
    font-size: 1.5*$base-font-size;
    display: block;
}
