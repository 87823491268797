h1 {
	font-family: 'Poiret One', sans-serif;
	font-size: 3*$base-font-size;
	text-transform: uppercase;
	font-weight: normal;
}

h2 {
	font-family: 'Poiret One', sans-serif;
	font-size: 1.75*$base-font-size;
	font-weight: bold;
	color: #fff;
}

.h2--lead {
	@extend .cursive;
	color: $base-brand-color;
	font-size: 2.5*$base-font-size;
	margin-bottom: 2*$base-spacing-unit;
	text-align: center;
	font-weight: normal;
}

h3 {
	@extend .muted;
	font-family: 'Poiret One', sans-serif;
	font-size:1.25*$base-font-size;
	font-weight: normal;
	margin: 0;
}

.indent {
	padding-left: 1.25*$base-spacing-unit;
}

.indent--small {
	padding-left: 0.25*$base-spacing-unit;
}

.muted {
	color: lighten($secondary-brand-color, 67%);
}

strong {
		@extend h3;
}

.lead {
	font-size: 1.25 * $base-font-size;
}

.list-style-none {
	margin-left: 0;
	list-style: none;
}

.glossary {
	margin-left: 0;
	list-style: none;
	font-style: italic;

	strong{
		margin-right: 4px;
	}
}

.glossary__extended {
	color: $base-brand-color;
	@extend strong;
	font-style: normal;
}

.glossary__info {
	display: block;
	font-style: normal;
	font-size: 0.85*$base-font-size;
	font-family: $base-font-family;
	color: $base-font-size;
}

.align--right {
	text-align: right;
}

.align--left {
	text-align: left;
}

.cursive {
	font-family: 'Poiret One', sans-serif;
}
